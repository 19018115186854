import React, { useEffect } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { Drawer, Collapse } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FaEarthAmericas } from "react-icons/fa6";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Settings,
  Lock,
  DashboardOutlined,
  GavelOutlined,
  PeopleAltOutlined,
  LeakAdd,
  DescriptionOutlined,
  DeviceHub,
  Tune,
  NotificationImportant,
  ScatterPlot,
  DynamicFeed,
} from "@material-ui/icons";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import MenuIcon from "@material-ui/icons/Menu";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import smartParkService from "../axios";
import {
  hasLoaded_,
  login_,
  updatesessionid,
  usersessionid_,
  token_,
} from "../reducers/login";
import { sensors_, sensorServiceThunk } from "../reducers/sensors";
import { fields_, fieldsServiceThunk } from "../reducers/fields";
import {
  permissions_,
  Getaccesspermissions,
  hasLoadedpermissions_,
} from "../reducers/permissions";
import Manufacturer from "../views/Manufacturer/Manufacturer";
import { checkPermissions } from "../utils/checkPermissions";

const drawerWidth = 215;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiDrawer-paper": {
      backgroundColor: "#03354b",
      color: "#fff",
    },
    "& .MuiListItem-gutters": {
      paddingLeft: "13px",
    },
    "& .MuiList-root": {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "-7px",
      },
    },
  },
  padL25: { paddingLeft: "25px" },
  padL35: { paddingLeft: "35px" },
  mainSidebar: {},
  collapseMenu: {
    marginLeft: "10px",
  },
  navBarMenu: {
    float: "right",
  },
  menuButton: {
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  menucloseButton: {
    marginRight: 36,
    justifyContent: "flex-end",
    color: "#fff",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(5) + 1,
    },
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(5) + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  nested: {
    paddingLeft: theme.spacing(100),
  },
}));

const SBMList = styled(List)`
  .muicollapse-container: {
    padding-left: 37px;
  }
  .MuiListItem-root {
    border-left: 3px solid transparent;
    .MuiListItemIcon-root,
    .MuiListItemText-root {
      color: #fff;
    }
    .MuiListItemIcon-root {
      min-width: 40px;
      color: #fff;
    }
    .MuiListItemText-root {
      font-weight: bold;
    }
    &:hover,
    &:active {
      .MuiListItemIcon-root,
      .MuiListItemText-root {
        color: #5fd0ee;
      }
      border-left: 3px solid #5fd0ee;
    }
  }
`;
export default function MainSidebar(props) {
  const loginHasLoaded = useSelector(hasLoaded_);
  const loginDetails = useSelector(login_);
  const usersessionid = useSelector(usersessionid_);
  const role = _.get(loginDetails, ["Role"], "");
  const token = useSelector(token_);
  smartParkService.defaults.headers.common["x-access-token"] = token;
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [arrow, setarrow] = React.useState(true);

  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const handleSettingsClick = () => {
    setarrow(!arrow);
    setSettingsOpen(!settingsOpen);
  };
  const navigateToAssetManagement = () => history.push("/AssetManagement");
  const manufacturer = () => history.push("/Manufacturer");
  const navigateToDashboard = () => history.push("/overview");
  // const navigateToRules = () => history.push("/Rules");
  const navigateToRules = () =>
    history.push({
      pathname: "/Rules",
      search: "", // Adding query parameters to the URL
      state: { detail: "Rule List" },
    }); // Adding state data
  const navigateToUsers = () => history.push("/Users");
  const navigateToReports = () => history.push("/reports");
  const navigateToAccessManagment = () => history.push("/AccessManagment");
  const navigateToDemoEvent = () => history.push("/RuleLogpage");
  const navigateToTimeZone = () => history.push("/TimeZone");
  const navigateToAdminDashboard = () => {
    history.push("/Settings");
  };
  const navigateToFields = () => history.push("/Sensor");
  const navigateToDevice = () => history.push("/Device");
  const navigateToNotification = () => history.push("/notification");
  const notifyMe = () => history.push("/field");

  const navigationHandler = ({ path, search, state }) => {
    history.push({
      pathname: path,
      search: search,
      state: state,
    });
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [permission, setpermission] = React.useState([]);

  const [dashboard, setDashboard] = React.useState({});
  const [rule, setRule] = React.useState({});

  const [usermanagement, setUserManagement] = React.useState({});
  const [accessManagment, setAccessManagment] = React.useState({});
  const [settings, setSettings] = React.useState({});
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [fields, setFields] = React.useState({});
  const [sensors, setSensors] = React.useState({});
  const [devices, setDevices] = React.useState({});
  const [administration, setAdministration] = React.useState({});
  const [notificationTemplate, setNotificationTemplate] = React.useState({});

  const permissionsHasLoaded = useSelector(hasLoadedpermissions_);
  const permissionsobj = useSelector(permissions_);

  useEffect(() => {
    if (loginHasLoaded) {
      const rolearray = _.get(loginDetails, ["Role"], "");
      var keys = Object.keys(rolearray);
      var filtered = keys.filter(function (key) {
        return rolearray[key];
      });

      const parkid = _.get(loginDetails, ["parkID"], "");
      const userID = _.get(loginDetails, ["UserID"], "");
      parkid &&
        dispatch(
          Getaccesspermissions({ parkid, query: filtered.join(","), token })
        );
      parkid && dispatch(sensorServiceThunk(parkid));
      parkid && dispatch(fieldsServiceThunk(parkid));
    }
  }, [loginHasLoaded]);

  useEffect(() => {
    if (permissionsHasLoaded && permissionsobj && permissionsobj.length > 0) {
      getpermissions();
    }
  }, [permissionsHasLoaded]);
  const getpermissions = () => {
    var permissionsobject = permissionsobj;
    setDashboard(
      permissionsobject.filter(function (e) {
        return e.feature == "UserDashboard";
      })[0]
    );
    setRule(
      permissionsobject.filter(function (e) {
        return e.feature == "RuleEngine";
      })[0]
    );
    setUserManagement(
      permissionsobject.filter(function (e) {
        return e.feature == "UserManagment";
      })[0]
    );
    setAccessManagment(
      permissionsobject.filter(function (e) {
        return e.feature == "AccessManagment";
      })[0]
    );
    setSettings(
      permissionsobject.filter(function (e) {
        return e.feature == "Settings";
      })[0]
    );
    setFields(
      permissionsobject.filter(function (e) {
        return e.feature == "Fields";
      })[0]
    );
    setSensors(
      permissionsobject.filter(function (e) {
        return e.feature == "Sensor";
      })[0]
    );
    setDevices(
      permissionsobject.filter(function (e) {
        return e.feature == "Device";
      })[0]
    );
    setNotificationTemplate(
      permissionsobject.filter(function (e) {
        return e.feature == "NotificationTemplate";
      })[0]
    );
    setAdministration(
      permissionsobject.filter(function (e) {
        return e.feature == "Administration";
      })[0]
    );
  };
  return (
    <div
      className={classes.root}
      onMouseEnter={() => {
        setarrow(true);
        // Set a new timeout
        const newTimeoutId = setTimeout(() => {
          setOpen(true);
        }, 1000);
        // Save the new timeoutId to the state
        setTimeoutId(newTimeoutId);
      }}
      onMouseLeave={() => {
        // Clear the timeout on mouse leave
        clearTimeout(timeoutId);
        setarrow(false);
        setSettingsOpen(false);
        setOpen(false);
      }}
    >
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <div className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              onClick={handleDrawerClose}
              className={clsx(classes.menucloseButton, {
                [classes.hide]: !open,
              })}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <SBMList component="nav" aria-labelledby="sidebar-menu">
            {checkPermissions({
              permission: "UserDashboard",
              permissionType: "read",
            }) && (
                <ListItem
                  button
                  onClick={() => {
                    navigationHandler({ path: "/overview" });
                  }}
                  className="listmenusidebar"
                >
                  <ListItemIcon>
                    <DashboardOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" disableTypography />
                </ListItem>
              )}

            {checkPermissions({
              permission: "RuleEngine",
              permissionType: "read",
            }) && (
                <ListItem
                  button
                  onClick={() => {
                    navigationHandler({
                      path: "/Rules",
                      state: { detail: "Rule List" },
                    });
                  }}
                  className="listmenusidebar"
                >
                  <ListItemIcon>
                    <GavelOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Rules" disableTypography />
                </ListItem>
              )}
            {checkPermissions({
              permission: "UserManagment",
              permissionType: "read",
            }) && (
                <ListItem
                  button
                  onClick={navigateToUsers}
                  className="listmenusidebar"
                >
                  <ListItemIcon>
                    <PeopleAltOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Users" disableTypography />
                </ListItem>
              )}
            {/* {parseInt(dashboard.read) ? (
              <ListItem
                button
                onClick={navigateToReports}
                className="listmenusidebar"
              >
                <ListItemIcon>
                  <DescriptionOutlined />
                </ListItemIcon>
                <ListItemText primary="Reports" disableTypography />
              </ListItem>
            ) : (
              ""
            )} */}
            {checkPermissions({
              permission: "AccessManagment",
              permissionType: "read",
            }) && (
                <ListItem
                  button
                  onClick={navigateToAccessManagment}
                  className="listmenusidebar"
                >
                  <ListItemIcon>
                    <Lock />
                  </ListItemIcon>
                  <ListItemText primary="Access Management" disableTypography />
                </ListItem>
              )}
            {checkPermissions({
              permission: "Timezone",
              permissionType: "read",
            }) && (
                <ListItem
                  button
                  className="listmenusidebar"
                  onClick={navigateToTimeZone}
                >
                  <ListItemIcon style={{ fontSize: "16px", paddingLeft: "3px" }}>
                    <FaEarthAmericas />
                  </ListItemIcon>
                  <ListItemText primary="TimeZone" disableTypography />
                </ListItem>
              )}
            {/* <Collapse
              in={settingsOpen}
              timeout="auto"
              unmountOnExit
              className={classes.padL25}
            >
              <List component="div" disablePadding>
              {parseInt(fields.read) ? (
									<ListItem button className={classes.nested} onClick={notifyMe}>
										<ListItemIcon>
                      <ScatterPlot />
										</ListItemIcon>
										<ListItemText primary="Fields" disableTypography />
									</ListItem>
								) : (
									""
								)}
                {parseInt(administration.read) ? (
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={navigateToAdminDashboard}
                  >
                    <ListItemIcon>
                      <Tune />
                    </ListItemIcon>
                    <ListItemText primary="Administration" disableTypography />
                  </ListItem>
                ) : (
                  ""
                )}
              </List>
              <List component="div" disablePadding>
                {parseInt(sensors.read) ? (
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={navigateToFields}
                  >
                    <ListItemIcon>
                      <LeakAdd />
                    </ListItemIcon>
                    <ListItemText primary="Sensors" disableTypography />
                  </ListItem>
                ) : (
                  ""
                )}
                {parseInt(devices.read) ? (
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={navigateToDevice}
                  >
                    <ListItemIcon>
                      <DeviceHub />
                    </ListItemIcon>
                    <ListItemText primary="Devices" disableTypography />
                  </ListItem>
                ) : (
                  ""
                )}
                {parseInt(notificationTemplate.read) ? (
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={navigateToNotification}
                  >
                    <ListItemIcon>
                      <NotificationImportant />
                    </ListItemIcon>
                    <ListItemText
                      primary="Notification Template"
                      disableTypography
                    />
                  </ListItem>
                ) : (
                  ""
                )}
									<ListItem button className={classes.nested} onClick={manufacturer}>
										<ListItemIcon>
											<DynamicFeed />
										</ListItemIcon>
										<ListItemText primary="Manufacturers" disableTypography />
									</ListItem>
								
              </List>
            </Collapse> */}
          </SBMList>
        </div>
      </Drawer>
    </div>
  );
}
